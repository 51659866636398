body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*===============================
# Home Page
=================================*/
.content-section-wrapper {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}
.form-container-of-demo-form-modal-box .css-11z5sw6-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
  /* color: transparent !important; */
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.form-container-of-demo-form-modal-box button,
.rounded-button-mui {
  background-color: #2406f9 !important;
  color: white !important;
  text-transform: inherit !important;
  border-radius: 50px !important;
  padding: 0 25px !important;
  font-family: "Prompt" !important;
  height: 50px !important;
}
.form-container-of-demo-form-modal-box button:hover, .form-container-of-demo-form-modal-box button:focus,
.rounded-button-mui:hover, .rounded-button-mui:focus{
  background-color: #1700ba !important;
}
.rounded-outlined-button-mui{
  background-color: transparent !important;
  border: 2px solid #E0E0E0 !important;
  text-transform: inherit !important;
  border-radius: 50px !important;
  padding: 0 25px !important;
  color: #686868 !important;
  height: 50px !important;
  font-family: "Prompt" !important;
}
.rounded-outlined-button-mui:hover, .rounded-outlined-button-mui:focus{
  background-color: #E0E0E0 !important;
}
.header-button-right-side {
  position: relative;
  width: 100%;
  height: 100%;
}
.header-button-right-side button{
  display: block;
  margin-left: 5px;
}
.content-of-header-button-right-side {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.img-box-of-intro-section-home-page img {
  width: 100%;
  max-width: 100%;
}
.img-box-of-intro-section-home-page {
  width: 50%;
}
.text-box-of-intro-section-home-page{
  width: 50%;
}
.wrapper-of-intro-section-home-page {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.intro-section-home-page {
  padding-top: 100px;
}
.text-box-of-intro-section-home-page h1,
.text-box-of-intro-section-home-page h3{
  margin: 0;
  font-size: 32px;
}
.text-box-of-intro-section-home-page p {
  margin: 15px 0 0;
}
.text-box-of-intro-section-home-page button {
  margin-top: 35px;
}
.content-of-text-box-of-intro-section-home-page {
  position: relative;
  width: 90%;
}
/*===============================
# After intro section
=================================*/
.items-after-intro-section {
  position: relative;
  width: 100%;
  background-color: #2B0FF8;
  margin-top: 100px;
}
.single-item-in-line-box{
  position: relative;
  box-sizing: border-box;
  width: 30%;
}
.content-of-single-item-in-line-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  box-sizing: border-box;
}
.items-in-line-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 100px 0;
}
.single-item-in-line-box h4{
  margin: 15px 0 0;
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}
.single-item-in-line-box h3{
  margin: 25px 0 0;
  color: white;
  text-align: center;
  font-size: 18px;
}
.why-nfc-card-section-container {
  padding-top: 100px;
}
.search-field-container {
  padding-top: 35px;
}
/*===============================
# Features
=================================*/
.features-container-section{
  padding-top: 50px;
}
.video-box-of-single-feature-container{
  cursor: url("./assets/svg/view-icon.svg") 2 2, pointer;
}
.content-of-video-auto-play-container-section {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 35px;
  box-sizing: border-box;
}
.video-auto-play-container-section.active {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: url("./assets/svg/close-icon.svg") 2 2, pointer;
  background-color: #000000a8;
}
.video-auto-play-container-section.active video {
  box-shadow: none;
  border-radius: 0;
}
.video-box-of-single-feature-container video {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 8px 20px #0000002e;
}
.single-feature-container.grayed-out-feature {
  background-color: #EDEDED;
}
.content-of-single-feature-container {
  position: relative;
  padding: 100px 0;
}
.wrapper-of-single-feature-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mirrored-container .content-of-video-content-box-of-single-feature-container{
  padding-right: 0;
  padding-left: 50px;
}
.content-of-video-content-box-of-single-feature-container {
  padding-right: 50px;
}
.text-box-of-single-feature-container h3{
  font-size: 24px;
  margin: 0;
}
.text-box-of-single-feature-container p{
  margin: 15px 0 0;
}
.video-content-box-of-single-feature-container{
  width: 50%;
}
.text-box-of-single-feature-container{
  width: 50%;
}
.more-features-blue-section{
  margin-top: 50px;
}
/*===============================
# Faq
=================================*/
.faq-section-container {
  padding-top: 100px;
}
.single-question-box {
  margin-bottom: 10px;
}
.single-question-box .MuiAccordionSummary-root {
  box-shadow: 0px 5px 10px #0000001a;
  background-color: #2a0ff8;
  color: white;
}
.single-question-box svg{
  color: white;
}
.single-question-box .MuiCollapse-wrapperInner{
  background-color: white;
  color: black;
}
.single-question-box .faq-description{
  font-family: 'Prompt';
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 2 !important;
}
.single-question-box .MuiAccordionSummary-content p {
  line-height: 50px;
  font-size: 18px;
  font-family: 'Prompt';
  font-weight: 600;
}
.single-question-box .MuiAccordionSummary-content {
  height: 50px;
}
.single-question-box .MuiAccordionDetails-root{
  padding: 25px 18px;
}
.section-title{
  font-size: 32px;
  margin: 0;
  text-align: center;
}
.wrapper-of-faq-section-container {
  padding-top: 40px;
}
/*===============================
# Demo form
=================================*/
.demo-form-container-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  visibility: hidden;
}
.demo-form-container-wrapper.active{
  visibility: visible;
}
.demo-form-container-wrapper.active .mask-of-demo-form-container-wrapper{
  opacity: 0.5;
}
.mask-of-demo-form-container-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9995;
  background-color: black;
  opacity: 0;
  cursor: url("./assets/svg/close-icon.svg") 2 2, pointer;
  transition: all 0.4s ease-in-out;
}
.demo-form-container-wrapper.active .demo-form-modal-box{
  transform: scale(1);
}
.demo-form-modal-box {
  position: relative;
  width: 700px;
  /* height: 100%; */
  background-color: white;
  z-index: 9999;
  border-radius: 25px;
  overflow: hidden;
  transform: scale(0);
  transition: all 0.4s ease-in-out;
}
.content-of-demo-form-container-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-of-demo-form-modal-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 35px;
  box-sizing: border-box;
}
.header-box-of-demo-form-modal-box h3{
  font-size: 24px;
  margin: 0;
}
.header-box-of-demo-form-modal-box p {
  margin: 15px 0 0;
}
.close-icon-box-of-header-box-of-demo-form-modal-box {
  position: absolute;
  top: 30px;
  right: 35px;
}
.form-container-of-demo-form-modal-box {
  padding-top: 35px;
}
/*===============================
# ReCaptcha
=================================*/
.single-form-element-container iframe {
  width: 100% !important;
}
.google-recaptcha-box {
  transform: scale(1.072);
  transform-origin: 0 0;
  margin-top: -15px;
  padding-top: 10px;
}
/*===============================
# Help Center
=================================*/
.wrapper-of-help-sections-boxes-container {
  padding-top: 50px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
}
.single-help-section-box {
  position: relative;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 50px;
  overflow: hidden;
}
.thumb-box-of-single-help-section-box{
  border-bottom: 1px solid #c4c4c4;
}
.thumb-box-of-single-help-section-box img {
  width: 100%;
}
.content-of-thumb-box-of-single-help-section-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-of-help-sections-boxes-container a {
  text-decoration: none;
}
.title-box-of-single-help-section-box h3{
  text-align: center;
  color: black;
  margin: 0;
}
.content-of-title-box-of-single-help-section-box{
  padding: 35px;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
/*===============================
# Breadcrumbs
=================================*/
.content-of-breadcrumbs-container {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
}
.text-box-of-intro-section-home-page .breadcrumbs-container {
  padding-top: 15px;
}
.breadcrumbs-container a{
  text-decoration: none;
  color: black;
  transition: all 0.35s ease-in-out;
}
.breadcrumbs-container a.no-link{
  color: #616161;
}
.breadcrumbs-container a:hover, .breadcrumbs-container a:focus{
  color: #2406F9;
}
.breadcrumbs-container a.no-link:hover, .breadcrumbs-container a.no-link:focus{
  color: black;
}
.breadcrumb-separator-icon svg {
  width: 15px;
  height: 15px;
  margin: 6px 0 0;
}
.breadcrumb-separator-icon {
  padding: 0 5px;
}
/*===============================
# Articles
=================================*/
.text-box-of-article-single-item-box a{
  text-decoration: none;
}
.text-box-of-article-single-item-box h3{
  margin: 0;
}
.text-box-of-article-single-item-box h4{
  margin: 0;
  font-size: 15px;
  font-weight: 400;
}
.articles-container {
  padding-top: 50px;
}
.article-single-item-box {
  position: relative;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 10px;
}
.content-of-article-single-item-box {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 35px;
  display: flex;
  align-content: center;
  align-items: center;
}
.text-box-of-article-single-item-box {
  position: relative;
  margin-right: 20px;
  min-width: 300px;
}
.body-of-single-article-page-container{
  padding-top: 50px;
}
.body-of-single-article-page-container img{
  width: 100%;
}
/*===============================
# 
=================================*/

/*===============================
# 
=================================*/

/*===============================
# 
=================================*/

/*===============================
# 
=================================*/

/*===============================
# Responsive 
=================================*/
@media screen and (max-width: 1024px){
  .content-section-wrapper {
    max-width: 95%;
  }
  .grid-item-header-buttons {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
    display: none;
  }
  .grid-item-header-menu-section{
    width: 100% !important;
    flex-basis: 83.333333% !important;
    max-width: 100% !important;
  }
  .wrapper-of-help-sections-boxes-container {
    display: block;
  }
}
@media screen and (max-width: 786px){
  .wrapper-of-intro-section-home-page {
    display: block;
  }
  .text-box-of-intro-section-home-page{
    width: 100%;
  }
  .img-box-of-intro-section-home-page{
    width: 100%;
    margin-top: 50px;
  }
  .items-in-line-container {
    display: block;
  }
  .single-item-in-line-box{
    width: 100%;
    margin-bottom: 50px;
  }
  .wrapper-of-single-feature-container{
    display: block;
  }
  .video-content-box-of-single-feature-container {
    width: 100%;

  }
  .content-of-video-content-box-of-single-feature-container{
    padding-right: 0;
    padding-left: 0;
  }
  .text-box-of-single-feature-container{
    width: 100%;
    padding-top: 35px;
  }
  .mirrored-container .content-of-video-content-box-of-single-feature-container{
    padding-right: 0;
    padding-left: 0;
  }
  .content-of-video-auto-play-container-section{
    padding: 0;
  }
  .single-feature-container.mirrored-container .video-content-box-of-single-feature-container {
    padding-top: 50px;
  }
  .single-question-box .MuiAccordionSummary-content {
    height: auto;
  }
  .demo-form-modal-box{
    width: 100%;
  }
  .content-of-demo-form-container-wrapper{
    box-sizing: border-box;
    padding: 0 35px;
  }
  .content-of-article-single-item-box {
    display: block;
  }
  .text-box-of-article-single-item-box{
    margin-right: 0;
    margin-bottom: 35px;
    width: 100%;
    min-width: 100%;
  }
  .text-box-of-article-single-item-box:last-child{
    margin-bottom: 0;
  }
  .google-recaptcha-box{
    transform: scale(1);
    margin-top: 0;
    padding-top: 0;
  }
}